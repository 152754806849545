import React, { useState, useEffect, useCallback } from "react";
import Autosuggest from "react-autosuggest";
import debounce from 'lodash.debounce';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import { hotelAutoComplete } from "../../../services/support";
import { IHotelAutoComplete, IinputProps } from "./HotelAutoCompleteProps";
import {
  InputWrapper,
  SectionContainer,
  SearchResults,
} from "./HotelAutoCompleteHelpers";

const HotelAutocomplete = (props: IHotelAutoComplete) => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState(props.suggestions || []);

  const { onHotelSelection, id, name, inputRef } = props;
  const [errors, setErrors] = useState(props.errors);
  const [highlightedSuggestion, setHighlightedSuggestion] = useState<any>(null);

  useEffect(() => {
    if (props.onAutoCompleteChange) {
      props.onAutoCompleteChange(value);
    }
  }, [value]);
  const onAutocompleteChange = (
    _event: React.ChangeEvent,
    { newValue }: { newValue: string }
  ) => {
    setValue(newValue);
    errors && setErrors(null);
    return newValue;
  };

  const handleSearchFocus = (event: any) => {
    event.target.setSelectionRange(0, event.target.value.length);
  };

  const handleBlur = (
    _event: React.FocusEvent,
    { highlightedSuggestion }: { highlightedSuggestion: any }
  ) => {
    if (!highlightedSuggestion) {
      onHotelSelection ? onHotelSelection("") : null;
      // setValue("");
      setErrors({ message: "Please select valid hotel." });
    } else {
      onHotelSelection ? onHotelSelection(highlightedSuggestion) : null;
      setValue(highlightedSuggestion.name);
    }
  };

  const inputProps: IinputProps = {
    value,
    onChange: onAutocompleteChange,
    onClick: handleSearchFocus,
    onBlur: handleBlur,
    className: `rounded form-control ${errors && "is-invalid"}`,
    id: id,
    name: name,
    ref: inputRef,
  };

  const renderInputComponent = (
    inputProps: JSX.IntrinsicAttributes &
      React.ClassAttributes<HTMLInputElement> &
      React.InputHTMLAttributes<HTMLInputElement>
  ) => (
    <InputWrapper>
      <span className={`${props.customIconClass} searchIcon`}>
        <FontAwesomeIcon icon={faMapMarkerAlt} />
      </span>
      <label htmlFor="hotelName" className="visuallyHidden">
        Hotel Name
      </label>
      <input
        {...inputProps}
        aria-required={true}
        aria-label="Hotel Name"
        id="hotelName"
        autoComplete="off"
      />
      <Form.Control.Feedback type="invalid">
        {errors && errors.message}
      </Form.Control.Feedback>
    </InputWrapper>
  );

  const fetchSuggestions = useCallback(
    debounce(async (searchValue) => {
      try {
        const result = await hotelAutoComplete(searchValue);
        result.sort((a, b) => a.name.localeCompare(b.name));
        setSuggestions(result);
      } catch (error) {
        console.error('Failed to fetch hotel suggestions:', error);
      }
    }, 10),
    []
  );

  const onSuggestionsFetchRequested = ({ value }) => {
    fetchSuggestions(value);
  };

  const renderSuggestionsContainer = ({
    containerProps,
    children,
  }: {
    containerProps: any;
    children: any;
  }) => {
    return (
      <div {...containerProps}>
        <SectionContainer>
          <SearchResults>{children}</SearchResults>
        </SectionContainer>
      </div>
    );
  };

  const getSuggestionValue = (suggestion: any) => suggestion.name;

  const renderSuggestion = (suggestion: any) => (
    <span>
      {suggestion.name}
      {suggestion.field_address_administrative_area
        ? `, ${suggestion.field_address_administrative_area}`
        : ""}
    </span>
  );

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (
    _event: any,
    { suggestion }: { suggestion: string }
  ) => {
    if (suggestion) {
      onHotelSelection ? onHotelSelection(suggestion) : null;
      setHighlightedSuggestion(suggestion);
      setValue(suggestion.name);
    } else {
      onHotelSelection ? onHotelSelection("") : null;
      setHighlightedSuggestion("");
    }
    const inputElement = document.getElementById("hotelName");
    if (inputElement) {
      inputElement.blur();
    }
  };

  const shouldRenderSuggestions = (value: string) => {
    return value.trim().length > 0;
  };

  useEffect(() => {
    setErrors(props.errors);
  }, [props.errors]);

  useEffect(() => {
    return () => {
      fetchSuggestions.cancel();
    };
  }, [fetchSuggestions]);

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={getSuggestionValue}
      highlightFirstSuggestion={false}
      renderSuggestion={renderSuggestion}
      renderSuggestionsContainer={renderSuggestionsContainer}
      renderInputComponent={renderInputComponent}
      shouldRenderSuggestions={shouldRenderSuggestions}
      inputProps={inputProps}
      focusInputOnSuggestionClick={false}
      id={"hotel-suggestions-list"}
    />
  );
};

export default HotelAutocomplete;
