import React, { useEffect } from "react";
import {
  Edit_Reservation_Failure,
  Edit_Reservation_Request_Payload,
  Framedview_User_Agent,
  GATSBY_MOBILE_APP,
  Trip_Booking_Login_Failure,
  Trip_Booking_Request_Token,
} from "../../../@types/Constants";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setCheckout } from "../../../redux/slices/Checkout/checkout";
import {
  addCRMProfile,
  addCRMProfileCDP,
  login,
  logout,
} from "../../../redux/slices/Member/member";
import { resetSearch } from "../../../redux/slices/Search/search";
import { isBrowser } from "../../../utils/helpers";
import { PostMessage } from "../../../utils/webFramed";
import * as auth0Client from "../../../utils/auth0Client";
import { Storage } from "../../../utils/storage";
import { checkWebFramed } from "../../../redux/slices/WebFramed/WebFramed";
import { IWebFramedProps } from "./WebFramedProps";
import jwt_decode from "jwt-decode";

const WebFramed = (props: IWebFramedProps) => {
  const dispatch: any = useAppDispatch();
  const isLoggedIn = useAppSelector((state: any) => state.member.isLoggedIn);

  // Function to remove event listeners
  const removeListeners = () => {
    window.removeEventListener("message", handleEvent);
    document.removeEventListener("message", handleEvent);
  };

  // Function to handle the framed booking login
  const framedBookingLogin = async (token = "") => {
    if (!token) {
      // If no token is provided, log out the user if logged in and remove event listeners
      if (isLoggedIn) {
        dispatch(logout());
      }
      removeListeners();
      return;
    }
    try {
      let guestId;
      const user: { nickname: string } = await jwt_decode(token);
      if (user && user?.nickname) {
        guestId = user.nickname;
      }
      Storage.SetLocalStorageValue("isLoggedIn", "true");
      Storage.SetLocalStorageValue("auth-token", btoa(token));
      const tokenId = auth0Client.getToken();
      const data = {
        "https://www.sonesta.com/guestId": guestId,
      };
      auth0Client.updateUser(data);
      const addCRMProfileAction = addCRMProfileCDP(tokenId);
      // Dispatch addCRMProfileAction and login actions
      dispatch(addCRMProfileAction);
      dispatch(login(data));
    } catch (e) {
      console.error(e);
      PostMessage({
        type: Trip_Booking_Login_Failure,
        message: "User authentication failed",
      });
      if (props.isEditReservation && props.handleEditResPayload) {
        props.handleEditResPayload({}, "error");
        PostMessage({
          type: Edit_Reservation_Failure,
          message: "User authentication failed",
        });
      }
    }
    removeListeners();
  };

  // Function to handle the event
  const handleEvent = (message: any) => {
    isBrowser && PostMessage(message?.data);
    if (message?.data) {
      const data =
        message.data && typeof message.data === "string"
          ? JSON.parse(message.data)
          : message.data;
      if ("payload" in data) {
        framedBookingLogin(data?.payload?.accessToken);
        if (props.isEditReservation && props.handleEditResPayload) {
          props.handleEditResPayload(data);
        }
      }
    }
    return;
  };

  useEffect(() => {
    try {
      const isFramedView =
        navigator.userAgent.indexOf(Framedview_User_Agent) > -1 &&
        GATSBY_MOBILE_APP;
      // Dispatch checkWebFramed action based on whether it's a framed view
      dispatch(checkWebFramed(isFramedView));
      if (isFramedView) {
        dispatch(setCheckout({}));
        dispatch(resetSearch({}));
        isBrowser && window.addEventListener("message", handleEvent);
        document.addEventListener("message", handleEvent);
        if (props.isEditReservation) {
          PostMessage({ type: Edit_Reservation_Request_Payload });
        } else {
          PostMessage({ type: Trip_Booking_Request_Token });
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  return null;
};

export default WebFramed;
